import React, { Component } from 'react';
import { connect } from 'dva';
import 'bootstrap/dist/css/bootstrap.min.css';
//import styles from './IndexPage.css';
import '../App.css';
import '../Fonts.css';
import {IAppState} from "../models/app/AppState";
import {Navbar, Nav, NavDropdown} from 'react-bootstrap'
import {RouteComponentProps} from "react-router";
import AppHeader from "../components/AppHeader";
import AppSelectInput from "../components/AppSelectInput";
// @ts-ignore
import { SpeechSynthesis ,useSpeechSynthesis} from "react-speech-kit";
// @ts-ignore
import ReactRecord from 'react-record';
// @ts-ignore
import Lottie from "lottie-react-web";
// @ts-ignore
import SpeechRecognition from "react-speech-recognition";
import AppTextArea from "../components/AppTextArea";
import App from "../App";
import AppInput from "../components/AppInput";
function mapStateToProps(state:IAppState) {
  return {

  };
}

const mapDispatchToProps = {

};
interface IProp {
  listening:boolean
  recognition:any
  transcript:string;
  finalTranscript:string;
}
interface IState {
  recording:boolean;
  speechLang:string;
  speechLangCode:string
  translateLang:string;
  translateLangCode:string;
  translation:string
  selectedFont:string
  selectedFontColor:string,
  translateLangs:any[],
  isMobile:boolean,
  speechText:string,
  voices:any[]
  resize:boolean;
  appendNewLine:boolean;
  timerRunning:boolean;
  seconds:number;
  newTextToRead:string
}
class IndexPage extends Component<IProp & RouteComponentProps>  {
  constructor(props:any) {
    super(props);
  }
  timer:any;
  state:IState={
    recording:false,
    speechLang:'Hebrew',
    speechLangCode:'he',
    translateLang:'',
    translateLangCode:'',
    translation:'',
    speechText:'',
    selectedFont:'Arial',
    selectedFontColor:'white',
    translateLangs:[],
    isMobile:false,
    voices:[],
    resize:false,
    appendNewLine:false,
    timerRunning:false,
    seconds:0,
    newTextToRead:''
  };
  async componentWillMount() {
    this.setSpeechLang('he')
    window.addEventListener("resize", this.resize.bind(this));
    if(window.innerWidth<1090){
        this.setState({isMobile:true})
    }else{
        this.setState({isMobile:false})
    }
    let fonts=require('../assets/fonts.json').fonts;
    const googleTranslate = require("google-translate")('AIzaSyARdNOTVwI5ZMVxgTC562TlUZRaDCIIvNI');
    googleTranslate.getSupportedLanguages('en', this.handleTranslateLangs.bind(this));

    let str="";
    for(let i=0;i<fonts.length;i++){
        str+=`@import url('https://fonts.googleapis.com/css?family=${fonts[i].name}&display=swap');\n
            .font-${fonts[i].value}{\n
             font-family:\"${fonts[i].value}\" ;\n
            }\n`
    }

    //this.translate()
  }
  resize() {
    if(window.innerWidth<1090){
        this.setState({isMobile:true})
    }else{
        this.setState({isMobile:false})
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }
  handleTranslateLangs(err:any, languageCodes:any){

      let translateLangs=[];
      translateLangs.push({name:'ללא תרגום',value:''})
      for(let i=0;i<languageCodes.length;i++){
          translateLangs.push({name:languageCodes[i].name,value:languageCodes[i].language})
      }
      this.setState({translateLangs:translateLangs})
  }
  componentDidUpdate(prevProps: Readonly<IProp & RouteComponentProps>, prevState:IState , snapshot?: any): void {

    if(this.props.finalTranscript!=prevProps.finalTranscript){

      if(this.state.speechText.length==0){
        this.startTimer();
      }
      let text=this.props.finalTranscript.substr(this.state.speechText.length,this.props.finalTranscript.length-1);
      if(this.state.speechText.length==0){
        text=this.props.finalTranscript;
      }
      if(this.state.seconds>5 && this.state.speechText.length<=this.props.finalTranscript.length ){
        text=this.state.speechText+'\n'+text;
        this.setState({seconds:0})
      }else{
        text=this.state.speechText+' '+text;
        this.setState({seconds:0})
      }

      this.setState({speechText:text})
      this.translate(text);
    }

  }
  startTimer() {

    this.timer = setInterval(() => {
      console.log(this.state.seconds)
      this.setState({seconds:this.state.seconds+1})
    }, 1000);
  }
  translate(text:string){
    if(this.state.translateLangCode=='')
        return;
    const googleTranslate = require("google-translate")('AIzaSyARdNOTVwI5ZMVxgTC562TlUZRaDCIIvNI');

    googleTranslate.translate(text, this.state.translateLangCode, this.handleTranslate.bind(this));
  }
  handleTranslate(err:any, translation:any){
    if(!err){
      //alert(translation.translatedText);
      let text=translation.translatedText;
      let newText=text.substr(this.state.translation.length,text.length-1);

      this.setState({translation:translation.translatedText,newTextToRead:newText})
      return;
    }
  }
  setSpeechLang(langCode:string){
    let langName=require('../assets/langs.json').langs.filter((l:any)=>l.value==langCode)[0];

    this.setState({speechLangCode:langCode,speechLang:langName.name})
    this.props.recognition.lang=langCode;
  }
  setTranslateLang(langCode:string){

    let langName=this.state.translateLangs.filter((l:any)=>l.value==langCode)[0];
    this.setState({translateLangCode:langCode,translateLang:langName.name})
  }
  setFont(font:string){
    this.setState({selectedFont:font})
  }
  setFontColor(color:string){
    this.setState({selectedFontColor:color})
  }
  render() {
    const {  resetTranscript, browserSupportsSpeechRecognition,startListening,stopListening  }:any = this.props
    return (
      <div style={{direction:'rtl',width:'100%'}}>
        <div style={{backgroundColor:'rgba(255,255,255,1)',zIndex:5,width:'100%',height:'100%',position:'relative',marginBottom:50}}>
          {
            /*
            <AppHeader isMobile={this.state.isMobile}/>
            */
          }

          <div style={{paddingTop:50,display:'flex',justifyContent:'center',flexDirection:'column'}}>
            <h2 className={'applyDefaultFont'} style={{textAlign:'center',color:'#FB8530'}}>מערכת אוטומטית לתמלול ותרגום סימולטני בזמן אמת</h2>
            <p className={'applyDefaultFont'} style={{textAlign:'center',color:'#6F6F6F',fontSize:15,marginTop:15}}>בחר את שפת המקור ושפת התרגום.
              תוכל לבחור האם ברצונך לקבל גם תמלול וגם תרגום או רק אחד מהם במסך מלא.</p>
          </div>

          <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',width:'100%',marginTop:100}}>
            {
              !this.state.isMobile?(<div style={{display:'flex',justifyContent:'space-between',alignSelf:'center',width:'77%',height:40}}>
              <div style={{display:'flex',flex:0.23}}>
                <AppSelectInput isMobile={this.state.isMobile} value={this.state.speechLangCode} onChange={(langCode:string)=>this.setSpeechLang(langCode)} placeholder={'שפת מקור'} options={require('../assets/langs.json').langs}/>
              </div>
              <div style={{display:'flex',flex:0.23}}>
                <AppSelectInput isMobile={this.state.isMobile} value={this.state.translateLangCode} onChange={(langCode:string)=>this.setTranslateLang(langCode)} placeholder={'שפת תרגום'} options={this.state.translateLangs}/>
              </div>
              <div style={{display:'flex',flex:0.23}}>
                <AppSelectInput isMobile={this.state.isMobile} value={this.state.selectedFont}  onChange={(font:string)=>this.setFont(font)} placeholder={'סוג גופן'} options={require('../assets/fonts.json').fonts}/>
              </div>
              <div style={{display:'flex',flex:0.23}}>
                <AppSelectInput isMobile={this.state.isMobile} value={this.state.selectedFontColor}   onChange={(color:string)=>this.setFontColor(color)}   placeholder={'צבע גופן'} options={require('../assets/colors.json').colors}/>
              </div>
            </div>)
            :(<div >
                <div style={{display:'flex',justifyContent:'space-around',alignSelf:'center',width:'100%',height:40}}>
                      <div style={{display:'flex',flex:0.48}}>
                      <AppSelectInput isMobile={this.state.isMobile} value={this.state.speechLangCode} onChange={(langCode:string)=>this.setSpeechLang(langCode)} placeholder={'שפת מקור'} options={require('../assets/langs.json').langs}/>
                    </div>
                    <div style={{display:'flex',flex:0.48}}>
                      <AppSelectInput isMobile={this.state.isMobile} value={this.state.translateLangCode} onChange={(langCode:string)=>this.setTranslateLang(langCode)} placeholder={'שפת תרגום'} options={this.state.translateLangs}/>
                    </div>

                </div>
                <div style={{display:'flex',justifyContent:'space-around',alignSelf:'center',width:'100%',height:40,marginTop:10}}>
                    <div style={{display:'flex',flex:0.48}}>
                      <AppSelectInput isMobile={this.state.isMobile} value={this.state.selectedFont}  onChange={(font:string)=>this.setFont(font)} placeholder={'סוג גופן'} options={require('../assets/fonts.json').fonts}/>
                    </div>
                    <div style={{display:'flex',flex:0.48}}>
                      <AppSelectInput isMobile={this.state.isMobile} value={this.state.selectedFontColor}   onChange={(color:string)=>this.setFontColor(color)}   placeholder={'צבע גופן'} options={require('../assets/colors.json').colors}/>
                    </div>
                </div>
            </div>)

            }
            <div style={{width:this.state.isMobile?'90%':'40%',height:60,display:'flex',justifyContent:'space-around',alignSelf:'center',marginTop:this.state.isMobile?50:100}}>
              <button className={'recordButton'} onClick={stopListening}>
                <img src={require('../assets/pause.png')} style={{width:20,height:20}}/>
              </button>

              {this.props.listening?<div style={{width:60,height:60,position:'relative'}}>
                    <div style={{width:60,height:60,position:'absolute',top:-20,left:20,}}>
                      <Lottie
                          width={100}
                          height={100}
                          options={{
                            animationData: require('../assets/play_animation.json'),
                            loop: true,
                          }}
                      />
                    </div>
                  </div>
                  :<button className={'recordButton'} onClick={startListening}>
                    <img src={require('../assets/play.png')} style={{width:20,height:20}}/>
                  </button>}
              <button className={'recordButton'} onClick={resetTranscript}>
                <p className={'applyDefaultFont'} style={{marginBottom:0,marginTop:5,fontSize:14,color:'#fff',fontWeight:'bolder'}}>Reset</p>
              </button>

            </div>

                  <SpeechSynthesis style={{width:'100%'}} >
                        {({
                              speak,
                              cancel,
                              speaking,
                              supported,
                              voices
                          }:any) => {



                            return(
                                <div style={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                  <div style={{width:this.state.resize?'98%':this.state.isMobile?'96%':'77%',marginTop:50,display:'felx'}}>
                                    <img className={'textAreaButton'} onClick={()=>this.setState({resize:!this.state.resize})}  title={'הגדל'} src={this.state.resize?require('../assets/exit-2.png'):require('../assets/resizeall.png')} style={{float:'right',width:25,height:25,cursor:'pointer'}}/>
                                  </div>

                                  <AppTextArea resize={this.state.resize} voices={voices} onChange={(text:string)=>{
                                      this.setState ({speechText:text})
                                      this.translate(text)
                                    }} isMobile={this.state.isMobile} title={'תמלול '+this.state.speechLang} lang={this.state.speechLangCode} font={this.state.selectedFont} fontColor={this.state.selectedFontColor} marginTop={10} text={this.state.speechText}/>
                                  <div   style={{width:'100%',display:this.state.translateLangCode==''?'none':'flex',justifyContent:'center',alignItems:'center'}}>
                                    <AppTextArea voices={voices}  newTextToRead={this.state.newTextToRead} resize={this.state.resize}  onChange={(text:string)=>null} isMobile={this.state.isMobile} title={'תרגום '+ this.state.translateLang} lang={this.state.translateLangCode}  font={this.state.selectedFont} fontColor={this.state.selectedFontColor}  marginTop={15} text={this.state.translation}/>
                                  </div>
                                  <div style={{width:'100%',height:50}} />
                                </div>
                            )

                        }
                        }
                    </SpeechSynthesis>


          </div>
        </div>
        {
          /*
          <div style={{width:'100%',height:600,backgroundColor:'#fff',position:'relative'}}>
          <div style={{width:'100%',height:600,backgroundColor:'black',position:'fixed',bottom:0,zIndex:0,paddingTop:40,display:'flex',flexDirection:'column',alignItems:'center'}}>
              <h1 className={'applyDefaultFont'} style={{color:'#FB8530',textAlign:'center'}}>צור קשר</h1>
              <div style={{width:this.state.isMobile?'70%':'50%',marginTop:30,display:'flex',justifyContent:'space-between',flexDirection:this.state.isMobile?'column':'row'}}>
                <div style={{flex:0.48,display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                  <AppInput isMobile={this.state.isMobile} placeholder={'שם מלא'}/>
                  <div style={{marginTop:this.state.isMobile?10:0}}>
                    <AppInput isMobile={this.state.isMobile} placeholder={'דוא״ל'}/>
                  </div>
                  <div style={{marginTop:this.state.isMobile?10:0}}>
                    <AppInput isMobile={this.state.isMobile} placeholder={'מספר טלפון'}/>
                  </div>

                </div>
                <div style={{flex:0.48,display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                  <textarea  style={{minHeight:this.state.isMobile?100:200,marginTop:this.state.isMobile?10:0,height:this.state.isMobile?100:200,width:'100%',border:0,borderRadius:25,padding:10}}></textarea>
                </div>
              </div>
              <button className={'applyDefaultFont'} style={{marginTop:35,width:200,borderRadius:50,height:35,backgroundColor:'#FB8530',border:0,fontSize:19}}><p style={{marginTop:2,color:'#fff'}}>שלח</p></button>

              <div style={{width:'70%',height:1,backgroundColor:'white',marginTop:35}}/>
              <img style={{marginTop:35}} src={require('../assets/lofoW.png')}/>
          </div>

        </div>
           */
        }


      </div>
    );
  }
}
const options = {
  autoStart: false,
  continuous:true,
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(SpeechRecognition(options)(IndexPage));
/*
הנה הטקסט בלי נקודות:

כותרת:
מערכת אוטומטית לתמלול ותרגום סימולטני בזמן אמת
תת כותרת :
בחר את שפת המקור ושפת התרגום.
תוכל לבחור האם ברצונך לקבל גם תמלול וגם תרגום או רק אחד מהם במסך מלא.
 */
