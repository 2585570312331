//import React from 'react';
import React  from 'react'
import { Router, Route, Switch } from 'dva/router';

import IndexPage from './pages/IndexPage';

import {createBrowserHistory} from 'history'

function RouterConfig({ history }:any) {
    history=createBrowserHistory();
  return (
    <Router history={history}>
      <Switch>
          <Route path="/"  exact component={IndexPage} />

      </Switch>
    </Router>
  );
}

export default RouterConfig;
