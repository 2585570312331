import {IAppState} from "./AppState";


interface IAppModel{
    namespace:string
    state:IAppState
    reducers:any
    effects:any
    subscriptions:any
}
const AppModel:IAppModel={
    namespace: 'app',
    state: {

    },
    reducers: {
        updateState(state:any, { payload }:any) {
            return { ...state, ...payload };
        },
    },
    effects: {
        *saveHistory({payload}:any,{call,put}:any){
            alert("here")
        },
    },
    subscriptions: {
        setup({ dispatch }:any) {
            dispatch({ type: 'loadStorage' });
        },
    },
}
export default AppModel
