import dva from 'dva';

import './index.css';
import thunk from "redux-thunk";
//import createLoading from 'dva-loading';

// 1. Initialize
const app = dva({
    onAction:[thunk],
    onError(e, dispatch) {
        console.log("error!", e.message);
    },
});

// 2. Plugins
// app.use({});
//app.use(createLoading());
// 3. Model
app.model(require('./models/app/appModel').default);

// 4. Router
app.router(require('./router').default);

// 5. Start
app.start('#root');
