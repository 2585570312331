import React,{Component,useState} from "react";
import { Link } from "dva/router";
import '../App.css';
import '../Fonts.css';
// @ts-ignore
import Speech from 'react-speech';
// @ts-ignore
import { SpeechSynthesis ,useSpeechSynthesis} from "react-speech-kit";
import * as fs from "fs";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
// @ts-ignore
import { saveAs } from 'file-saver';
// @ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Toast } from "react-bootstrap";
var ReactCSSTransitionGroup = require('react-transition-group');

interface IProps {
    marginTop:number
    text:string
    font:string
    fontColor:string
    title:string
    lang:string
    isMobile:boolean
    onChange:Function
    voices:any[]
    resize:boolean;
    newTextToRead?:string
}
interface IState {
    fontSize:number
    bgColor:string
    showBgColorPick:boolean
    showFontColorPick:boolean
    fontColor:string
    resize:boolean;
    resizerHover:boolean;
    showMsg:boolean;
    msg:string,
    isSpeaking:boolean;
    speakingVoice:any,
    speakingFunction:any
    oldText:string;
    appendNewLine:boolean
}
class AppTextArea extends Component<IProps>{
    timer:any;
    state:IState={
        fontSize:17,
        bgColor:'black',
        showBgColorPick:false,
        showFontColorPick:false,
        fontColor:this.props.fontColor,
        resize:false,
        resizerHover:false,
        showMsg:false,
        msg:'',
        isSpeaking:false,
        speakingVoice:null,
        speakingFunction:null,
        oldText:'',
        appendNewLine:false

    };
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if(this.state.fontColor!=this.props.fontColor && this.props.fontColor!=prevProps.fontColor){
            this.setState({fontColor:this.props.fontColor})
        }
        if(prevProps.resize != this.props.resize){
            this.setState({resize:this.props.resize})
        }
        if(this.props.text!=prevProps.text){

            let AppTextArea=document.getElementsByClassName('AppTextArea');
            if(AppTextArea && AppTextArea[0]){
                AppTextArea[0].scrollTo({
                    top: AppTextArea[0].scrollHeight,
                    behavior: 'smooth'
                });
            }
            if(AppTextArea && AppTextArea[1]){
                AppTextArea[1].scrollTo({
                    top: AppTextArea[1].scrollHeight,
                    behavior: 'smooth'
                });
            }


        }
        if(this.props.newTextToRead!=prevProps.newTextToRead){

            if(this.props.newTextToRead && this.state.isSpeaking && this.state.speakingVoice && this.state.speakingFunction){
                this.state.speakingFunction({text:this.props.newTextToRead,voice:this.state.speakingVoice})
            }
        }
    }

    scroll(){
        let AppTextArea=document.getElementsByClassName('AppTextArea')[0];
        if(AppTextArea){
            AppTextArea.scrollTo({
                top: AppTextArea.scrollHeight,
                behavior: 'smooth'
            });
        }
    }
    speak(){
        SpeechSynthesis.speak(this.props.text)
    }
    isRtl(){
        let isRtl=false;
        if(this.props.lang=='he' || this.props.lang=='iw' || this.props.lang=='ar' || this.props.lang.includes('ar'))
            isRtl=true;
        return isRtl;
    }
    generateWordFile(){
        const doc = new Document()  ;
        let isRtl=false;
        if(this.props.lang=='he' || this.props.lang=='iw' || this.props.lang=='ar' || this.props.lang.includes('ar'))
            isRtl=true;
        doc.addSection({
            properties: {},
            children: [
                new Paragraph({
                    children: [
                        new TextRun(this.props.text),

                    ],
                    alignment:isRtl?AlignmentType.RIGHT:AlignmentType.LEFT
                }),
            ],
        });

        Packer.toBlob(doc).then((blob:any) => {

            saveAs(blob, "example.docx");
            this.setState({msg:'קובצ וורד נוצר בהצלחה',showMsg:true});
            setTimeout(()=> this.setState({msg:'',showMsg:false}), 1500)
        });
    }
    copyToClipBoard(){

    }
    render() {
        return (
            <div style={{backgroundColor:this.state.bgColor,width:this.state.resize?'98%':this.props.isMobile?'96%':'77%',height:this.state.resize?'95vh':280,marginTop:this.props.marginTop,borderRadius:10,display:'flex',flexDirection:this.props.isMobile?'column':'row'}}>

                <div style={{flex:this.props.isMobile?0.87:0.95,display:'flex',flexDirection:'column'}}>
                    <div style={{flex:this.props.isMobile?0.15:0.12,borderBottom:'1px solid #fff',display:'flex',alignItems:'center'}}>
                        <p className={'applyDefaultFont'} style={{color:'#fff',marginBottom:0,marginRight:this.props.isMobile?5:10,marginTop:4,textAlign:'right',fontSize:this.props.isMobile?12:15}}>{this.props.title}</p>
                        <p className={'applyDefaultFont'} style={{textAlign:'center',color:'black',marginBottom:0,paddingTop:this.props.isMobile?5:2,marginRight:this.props.isMobile?5:10,backgroundColor:'white',paddingRight:this.props.isMobile?5:20,paddingLeft:this.props.isMobile?5:20,height:25,fontSize:this.props.isMobile?12:15}}>{this.props.font}</p>

                        <div style={{backgroundColor:'white',height:25,width:this.props.isMobile?50:70,marginRight:10,display:'flex'}}>
                            <div className={'applyDefaultFont'} style={{flex:0.25,cursor:'pointer',justifyContent:'center',alignItems:'center',textAlign:'center'}} onClick={()=>this.setState({fontSize:this.state.fontSize+1})}>
                                +
                            </div>
                            <div className={'applyDefaultFont'} style={{flex:0.5,display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontSize:14}}>
                                {this.state.fontSize}
                            </div>
                            <div className={'applyDefaultFont'} style={{flex:0.25,cursor:'pointer',justifyContent:'center',alignItems:'center',textAlign:'center'}}  onClick={()=>this.setState({fontSize:this.state.fontSize>6?this.state.fontSize-1:this.state.fontSize})}>
                                -
                            </div>
                        </div>

                        <div  onClick={()=>this.setState({showFontColorPick:!this.state.showFontColorPick})} style={{cursor:'pointer',height:25,width:25,backgroundColor:this.state.fontColor,marginRight:10,border:'2px solid white',position:'relative'}}>
                            <div style={{width:100,backgroundColor:'white',position:'absolute',top:25,left:-40,display:this.state.showFontColorPick?'block':'none'}}>
                                {
                                    require('../assets/colors').colors.map((c:any)=>{
                                        if(c.value!='white'){
                                            return <div onClick={()=>this.setState({fontColor:c.value})} style={{width:25,height:25,backgroundColor:c.value,display:'inline-block',marginBottom:0,marginTop:0,lineHeight:0.5}}/>

                                        }
                                    })
                                }
                            </div>
                        </div>
                        <p className={'applyDefaultFont'} style={{fontSize:this.props.isMobile?12:15,color:'#fff',marginBottom:0,marginRight:this.props.isMobile?5:10,marginTop:4,textAlign:'right',}}>{"צבע רקע:"}</p>
                        <div onClick={()=>this.setState({showBgColorPick:!this.state.showBgColorPick})} style={{cursor:'pointer',height:25,width:25,backgroundColor:this.state.bgColor,marginRight:this.props.isMobile?3:10,border:'2px solid white',position:'relative'}}>
                            <div style={{width:100,backgroundColor:'white',position:'absolute',top:25,left:-40,display:this.state.showBgColorPick?'block':'none'}}>
                                {
                                    require('../assets/colors').colors.map((c:any)=>{
                                        if(c.value!='white'){
                                            return <div onClick={()=>this.setState({bgColor:c.value})} style={{width:25,height:25,backgroundColor:c.value,display:'inline-block',marginBottom:0,marginTop:0,lineHeight:0.5}}/>

                                        }
                                    })
                                }
                            </div>
                        </div>


                    </div>
                    <div style={{flex:this.props.isMobile?0.85:0.88,display:'flex',flexDirection:'column',overflow:'scroll'}}>
                        <div style={{flex:0.8}}>
                             <textarea  onChange={(event)=>this.props.onChange(event.target.value)} value={this.props.text} className={'AppTextArea font-'+this.props.font}  style={{resize:'none',backgroundColor:this.state.bgColor,minHeight:'100%',maxHeight:'100%',border:'0',height:'100%',width:'100%',textAlign:this.isRtl() ?'right':'left',padding:10,color:this.state.fontColor,fontSize:this.state.fontSize}}></textarea>
                        </div>
                        <div style={{flex:0.2,display:'flex',alignItems:'flex-end',height:'13%',overflow:'hidden',position:'relative'}}>


                                {/*<img className={'textAreaButton'} onMouseEnter={()=>this.setState({resizerHover:true})} onMouseLeave={()=>this.setState({resizerHover:false})} onClick={()=>this.setState({resize:!this.state.resize})} title={'הגדל'} src={require('../assets/resize1.png')} style={{marginBottom:12,float:'right',marginRight:10,cursor:'pointer'}}></img>

                                <Toast show={this.state.showMsg} style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: '35%',
                                    }}>

                                <Toast.Body>{this.state.msg}</Toast.Body>
                                </Toast>*/}
                                <div style={{flex:0.1,height:'100%',overflow:'hidden',display:'flex',alignItems:'flex-end'}}>
                                    <img className={'textAreaButton'} onMouseEnter={()=>this.setState({resizerHover:true})} onMouseLeave={()=>this.setState({resizerHover:false})} onClick={()=>this.setState({resize:!this.state.resize})} title={'הגדל'} src={this.state.resize?require('../assets/exit.png'):require('../assets/resize1.png')} style={{marginBottom:12,float:'right',marginRight:10,cursor:'pointer'}}></img>
                                </div>
                                <div style={{flex:0.9,height:'100%',overflow:'hidden',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                <Toast show={this.state.showMsg} style={{marginLeft:30}}>

                                <Toast.Body>{this.state.msg}</Toast.Body>
                                </Toast>
                                </div>



                        </div>
                    </div>
                </div>
                <div style={{flex:this.props.isMobile?0.13:0.05,display:'flex',flexDirection:this.props.isMobile?'row':'column',alignItems:'center',borderRight:this.props.isMobile?'0':'1px solid #fff',borderTop:this.props.isMobile?'1px solid #fff':'0',paddingLeft:5}}>
                    <img className={'textAreaButton'} title='צור קובץ וורד' onClick={()=>this.generateWordFile()} src={require('../assets/writeIcon.png')} style={{cursor:'pointer',marginTop:this.props.isMobile?0:20,marginRight:this.props.isMobile?20:0}}/>
                    <CopyToClipboard text={this.props.text} onCopy={() => {
                        this.setState({msg:'התוכן הועתק בהצלחה',showMsg:true});
                        setTimeout(()=> this.setState({msg:'',showMsg:false}), 1500)
                    }}>
                        <img  className={'textAreaButton'}  title='העתק' src={require('../assets/copy.png')} style={{cursor:'pointer',marginTop:this.props.isMobile?0:20,marginRight:this.props.isMobile?20:0}}/>
                    </CopyToClipboard>

                   <SpeechSynthesis  >
                        {({
                              speak,
                              cancel,
                              speaking,
                              supported,
                              voices
                          }:any) => {
                           // console.log(voices);
                            let voice= this.props.voices[4];
                            //if(this.props.lang!='he' && this.props.lang!='iw'){
                                try {
                                    voice=this.props.voices.filter((v:any)=>v.lang.includes(this.props.lang))[0];
                                }catch (e) {
                                    return <div/>
                                }
                                if(voice=='' || voice==null){
                                  //  alert(this.props.lang)
                                    return <div/>
                                }
                            //}

                            return(
                                <img title='השמעה' className={'textAreaButton'}  onClick={()=>{
                                    if(this.state.isSpeaking){
                                        this.setState({isSpeaking:false});
                                        return;
                                    }
                                    this.setState({isSpeaking:!this.state.isSpeaking,speakingVoice:voice,speakingFunction:speak})
                                    speak({text:this.props.text,voice:voice})
                                }} src={this.state.isSpeaking?require('../assets/stopSpeak.png'):require('../assets/speaker.png')} style={{cursor:'pointer',marginTop:this.props.isMobile?0:20,marginRight:this.props.isMobile?20:0}}/>

                            )

                        }
                        }
                    </SpeechSynthesis>
                </div>

            </div>
        );
    }

}
export default AppTextArea
