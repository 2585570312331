import React,{Component} from "react";
import { Link } from "dva/router";
import '../App.css';
import { Form } from "react-bootstrap";
import '../App.css';

interface IProps {
    placeholder:string
    options:any[]
    onChange:Function
    value:string
    isMobile:boolean
}
export default class AppSelectInput extends Component<IProps>{

    render() {
        return (
            <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'row'}}>
                <div style={{flex:0.35,display:'flex',justifyContent:'center',alignItems:'center',borderTopRightRadius:20,borderBottomRightRadius:20,backgroundColor:'#FB8530'}}>
                    <p className={'applyDefaultFont'} style={{textAlign:'center',marginBottom:0,color:'#fff',fontSize:this.props.isMobile?12:15,marginTop:2,lineHeight:1}}>{this.props.placeholder}</p>
                </div>
                <div style={{flex:0.65}}>
                    <Form.Control className={'appSelect'} as="select" value={this.props.value} onChange={(event)=>this.props.onChange(event.target.value)} custom style={{borderRadius:5,height:'100%',borderBottomLeftRadius:20,borderTopLeftRadius:20,borderColor:'#FB8530',borderWidth:2,borderRightWidth:0,borderBottomRightRadius:0,borderTopRightRadius:0}}>
                        <optgroup>
                            {
                                this.props.options.map(p=>{
                                    return(<option  value={p.value}>{p.name}</option>)
                                })
                            }
                        </optgroup>


                    </Form.Control>
                </div>
            </div>
        );
    }

}
